<!--
 * @Description: 添加/编辑物料关系
 * @Author: ChenXueLin
 * @Date: 2021-11-02 20:33:56
 * @LastEditTime: 2023-03-22 16:31:21
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    :title="dialogType == 1 ? '新增' : dialogType == 2 ? '编辑' : '详情'"
    :visible.sync="addVisible"
    :close-on-click-modal="false"
    width="950px"
    v-loading="editDialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
    :before-close="closeDialog"
    append-to-body
    v-dialogDrag
  >
    <div class="dialogTitle">
      <div class="line"></div>
      <div class="til">产品信息</div>
    </div>
    <el-form
      label-width="110px"
      :model="addForm"
      ref="addForm"
      :rules="addFormRules"
    >
      <el-row>
        <el-col :span="9">
          <el-form-item label="产品名称：" prop="productCode">
            <e6-vr-select
              is-title
              v-model="addForm.productCode"
              placeholder="产品名称"
              title="产品名称"
              clearable
              popperClass="popperClass"
              :data="productList"
              :props="{
                id: 'productCode',
                label: 'productName'
              }"
              :popper-append-to-body="false"
              @change="changeProduct"
              v-if="dialogType == 1"
            ></e6-vr-select>
            <el-input
              placeholder="产品名称"
              title="产品名称"
              disabled
              v-model="addForm.productName"
              v-else
              style="width:220px;"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="产品型号：" prop="productCode">
            <e6-vr-select
              is-title
              v-model="addForm.productCode"
              placeholder="产品型号"
              title="产品型号"
              clearable
              :data="productList"
              @change="changeProduct"
              :props="{
                id: 'productCode',
                label: 'productNo'
              }"
              v-if="dialogType == 1"
            ></e6-vr-select>
            <el-input
              placeholder="产品型号"
              title="产品型号"
              disabled
              v-model="addForm.productNo"
              v-else
              style="width:220px;"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="是否启用：" prop="status">
            <el-switch
              v-model="addForm.status"
              :active-value="activeValue"
              active-color="#46BFEA"
              :inactive-value="inactiveValue"
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-form label-width="110px" :model="addForm">
      <el-row>
        <el-col :span="8">
          <el-form-item label=" 类型：">
            {{ baseInfo.productType }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品分类：">
            {{ baseInfo.categoryName }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品名称:">
            {{ baseInfo.productName }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品型号：">
            {{ baseInfo.productNo }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态：">
            {{ baseInfo.status }}
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="产品ID：">
            {{ baseInfo.productCode }}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <div class="dialogTitle operate-action">
        <div class="line"></div>
        <div class="til">产品辅料关系</div>
        <template v-if="dialogType != 3">
          <i class="e6-icon-add_line1" title="新增" @click="handleAdd"></i>
          <i
            class="e6-icon-delete_line1"
            title="删除"
            @click="deleteColumns"
          ></i>
        </template>
      </div>
      <el-form ref="tableForm" :model="tableForm" :rules="tableFormRules">
        <el-table
          ref="table"
          highlight-current-row
          @selection-change="handleSelectionChange"
          :data="tableForm.tableData"
          max-height="300"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column align="center" width="220">
            <template slot="header">
              <span style="color:#F56C6C;">*</span>
              <span style="color:#48494c;">位置</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.installProcessId'"
                :rules="tableFormRules.installProcessId"
              >
                <e6-vr-select
                  is-title
                  v-model="scope.row.installProcessId"
                  placeholder="安装位置"
                  title="安装位置"
                  clearable
                  :disabled="dialogType == 3"
                  :data="positionList"
                  :props="{
                    id: 'installProcessId',
                    label: 'processName'
                  }"
                ></e6-vr-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="140">
            <template slot="header">
              <span style="color:#F56C6C;">*</span>
              <span style="color:#48494c;">辅料名称</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.accessoryKeyId'"
                :rules="tableFormRules.accessoryKeyId"
              >
                <remote-search
                  v-model="scope.row.accessoryKeyId"
                  clearable
                  placeholder="辅料名称"
                  title="辅料名称"
                  :row="scope.row"
                  :props="{
                    id: 'keyId',
                    label: 'accessoryName'
                  }"
                  :queryListAPI="getAccessoryList"
                  searchValueKey="accessoryName"
                  @change="changeRow"
                  :disabled="dialogType == 3"
                  :showInit="scope.row.accessoryKeyId ? false : true"
                  :defaultValue="
                    dialogType == 1
                      ? {}
                      : {
                          keyId: scope.row.accessoryKeyId,
                          accessoryName: scope.row.accessoryName,
                          accessoryId: scope.row.accessoryId,
                          accessoryClassStr: scope.row.accessoryClassStr
                        }
                  "
                ></remote-search>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="80">
            <template slot="header">
              <span style="color:#48494c;">辅料类型</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.accessoryClassStr'"
              >
                {{ scope.row.accessoryClassStr }}
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="100">
            <template slot="header">
              <span style="color:#48494c;">辅料ID</span>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="'tableData.' + scope.$index + '.accessoryId'"
              >
                {{ scope.row.accessoryId }}
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column align="center" width="150">
            <template slot="header">
              <span style="color:#48494c;">用量</span>
            </template>
            <template slot-scope="scope">
              <el-form-item :prop="'tableData.' + scope.$index + '.amount'">
                <el-input
                  v-model="scope.row.amount"
                  placeholder="请输入数量"
                  :disabled="dialogType == 3"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <div class="dialog-footer" slot="footer" v-show="dialogType != 3">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import {
  getProductAccessories,
  getAccessoryList,
  getPosition,
  getProductAccessoryDetail,
  saveOrUpdateProductAccessory
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "addProductRelationshipDialog",
  components: {},
  data() {
    return {
      editDialogLoading: false,
      companyList: [], //公司主体
      productList: [], //产品列表
      addForm: {
        productCode: "", //产品id
        status: 1,
        productAccessoriesId: "",
        productNo: "",
        productName: ""
      },
      positionList: [], //安装位置下拉框
      activeValue: 1, //启用id
      inactiveValue: 0, //关闭id
      baseInfo: {},
      addFormRules: {
        productCode: [
          {
            required: true,
            message: "请选择产品",
            trigger: ["blur", "change"]
          }
        ]
      },
      tableForm: {
        tableData: []
      },
      tableFormRules: {
        installProcessId: [
          {
            required: true,
            message: "请选择安装位置",
            trigger: ["blur", "change"]
          }
        ],
        accessoryKeyId: [
          {
            required: true,
            message: "请选择辅料名称",
            trigger: ["blur", "change"]
          }
        ]
        // usedCount: positiveIntegerValid.required({
        //   requiredMsg: "请输入用量",
        //   message: "只能为>0的整数",
        //   trigger: ["blur", "change"]
        // })
      },
      getAccessoryList,
      selectedData: [] //选中的数据
    };
  },
  //dialogType:1新增2编辑3详情
  props: ["addVisible", "dialogType", "detailInfo", "clickId"],
  mixins: [base],
  computed: {},
  created() {},
  watch: {
    addVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.addForm.clearValidate();
          });
          this.initData();
        }
      }
    }
  },
  methods: {
    async initData() {
      let promiseList = [getProductAccessories(), getPosition()];
      let [productRes, positionRes] = await Promise.all(promiseList);
      //产品列表
      this.productList = this.getFreezeResponse(productRes, {
        path: "data"
      });
      //安装位置下拉框
      this.positionList = this.getFreezeResponse(positionRes, {
        path: "data"
      });
      if (this.dialogType == 2 || this.dialogType == 3) {
        this.getDetail();
      }
    },
    //获取详情
    async getDetail() {
      try {
        let res = await getProductAccessoryDetail({ id: this.clickId });
        if (res.code == "OK") {
          this.addForm.productName = res.data.productName;
          this.addForm.productNo = res.data.productNo;
          this.addForm.productCode = res.data.productCode;
          this.addForm.productAccessoriesId = res.data.productAccessoriesId;
          this.addForm.status = res.data.isEnable;
          this.baseInfo = { ...res.data };
          delete this.baseInfo.accessoriesDetList;
          this.tableForm.tableData = res.data.accessoriesDetList;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    changeProduct(val, node) {
      this.baseInfo = { ...node };
    },
    //点击添加
    handleAdd() {
      let tableData = this.tableForm.tableData;
      tableData.push({
        installProcessId: "",
        accessoryKeyId: "",
        accessoryId: "",
        accessoryName: "",
        accessoryClassStr: "",
        amount: 1
      });
      this.tableForm.tableData = tableData;
    },

    //点击删除多行
    deleteColumns() {
      let tableData = this.tableForm.tableData;
      tableData = tableData.filter(item => {
        return !this.selectedData.some(
          i =>
            i.installProcessId == item.installProcessId &&
            item.accessoryKeyId == i.accessoryKeyId &&
            item.amount == i.amount
        );
      });
      this.$nextTick(() => {
        this.tableForm.tableData = [...tableData];
      });
    },

    //删除单行
    deleteColumn(index) {
      let tableData = _.cloneDeep(this.tableForm.tableData);
      tableData.splice(index, 1);
      this.tableForm.tableData = tableData;
    },
    //点击确定添加
    handleSubmit() {
      const p1 = new Promise(resolve => {
        this.$refs["addForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      const p2 = new Promise(resolve => {
        this.$refs["tableForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      Promise.all([p1, p2]).then(() => {
        this.saveOrUpdateProductAccessoryReq();
      });
    },
    //选择辅料名称
    changeRow(value, node, row) {
      row.accessoryId = node.accessoryId;
      row.accessoryClassStr = node.accessoryClassStr;
    },
    //多选勾中的数据
    handleSelectionChange(columns) {
      this.selectedData = columns;
    },
    //添加/编辑请求
    async saveOrUpdateProductAccessoryReq() {
      try {
        this.editDialogLoading = true;
        let res = await saveOrUpdateProductAccessory({
          ...this.addForm,
          accessoriesList: this.tableForm.tableData
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //关闭弹框
    closeDialog() {
      this.addForm = {
        productCode: "", //产品id
        status: 1,
        productAccessoriesId: ""
      };
      this.baseInfo = {};
      this.$refs.addForm.resetFields();
      this.tableForm.tableData = [];
      this.$emit("update:addVisible", false);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/utils.scss";
@import "@/assets/styles/variables.scss";
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 15px 20px;
    box-sizing: border-box;

    .el-form {
      .el-table {
        .el-form-item {
          margin-bottom: 12px;
          .e6-vr-select {
            width: 180px;
            .el-input {
              width: 180px;
            }
          }
          .el-input {
            width: 140px;
          }
        }
      }
    }
    .dialogTitle {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      width: 100%;
      justify-content: flex-start;
      .line {
        width: 4px;
        height: 25px;
        background: #46bfea;
        margin-right: 5px;
      }
      .til {
        font-size: 16px;
        font-weight: 500;
        margin-right: 15px;
      }
      i {
        margin-right: 10px;
        font-size: 20px;
        color: #46bfea;
      }
    }
  }
}
</style>
