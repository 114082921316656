<!--
 * @Description: 产品辅料库
 * @Author: ChenXueLin
 * @Date: 2021-10-14 08:59:17
 * @LastEditTime: 2022-11-25 15:54:09
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <el-button type="primary" @click="handleMaintenance">
            无需维护
          </el-button>
          <el-button type="primary" @click="handleAdd(1)">新增</el-button>
          <el-button type="primary" @click="changeStatus(1)">启用</el-button>
          <el-button type="primary" @click="changeStatus(0)">停用</el-button>
          <el-button type="primary" @click="importXlxs">导入</el-button>
          <el-button type="primary" @click="exportData">导出</el-button>
          <el-button type="primary" @click="lookPosition">安装位置</el-button>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="accessoryName">
              <el-input
                v-model="searchForm.accessoryName"
                placeholder="辅料名称"
                title="辅料名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="accessoryId">
              <el-input
                v-model="searchForm.accessoryId"
                placeholder="辅料ID"
                title="辅料ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="productCode">
              <el-input
                v-model="searchForm.productCode"
                placeholder="产品ID"
                title="产品ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="status">
              <e6-vr-select
                v-model="searchForm.status"
                :data="accessoryRelationStatusList"
                placeholder="单据状态"
                title="单据状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="accessoryClass">
              <e6-vr-select
                v-model="searchForm.accessoryClass"
                :data="accessoryClassList"
                placeholder="辅料类型"
                title="辅料类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="installProcessId">
              <e6-vr-select
                v-model="searchForm.installProcessId"
                :data="positionList"
                placeholder="安装位置"
                title="安装位置"
                clearable
                multiple
                :props="{
                  id: 'installProcessId',
                  label: 'processName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="productName">
              <el-input
                v-model="searchForm.productName"
                placeholder="产品名称"
                title="产品名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="productNo">
              <el-input
                v-model="searchForm.productNo"
                placeholder="产品型号"
                title="产品型号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="tableList"
          @selection-change="handleSelectionChange"
          row-key="productProcessId"
          :cell-class-name="isRender"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="80"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.sortNum }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <add-product-relationship-dialog
      :addVisible.sync="addVisible"
      :detailInfo="detailInfo"
      @refreshData="queryList"
      :dialogType="dialogType"
      :clickId="clickId"
    ></add-product-relationship-dialog>
    <!-- 安装位置 -->
    <install-position
      :tableVisible.sync="tableVisible"
      @close="close"
    ></install-position>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { exportData } from "@/utils/download";
import {
  findDownList,
  batchEnableRelation,
  unRelationProductAccessory,
  getProductAccessoryPageList,
  exportProductAccessory,
  getPosition
} from "@/api";
import { e3Api } from "@/config/api";
const { E3_BL_EWOS = "" } = e3Api;
import AddProductRelationshipDialog from "./addProductRelationshipDialog.vue";
import InstallPosition from "./InstallPosition.vue";
export default {
  name: "productAccessoriesStore",
  data() {
    return {
      loading: false,
      queryListAPI: getProductAccessoryPageList,
      searchForm: {
        accessoryName: "",
        accessoryId: "",
        status: "",
        productName: "",
        productNo: "",
        installProcessId: [],
        accessoryClass: "",
        productCode: "",
        pageIndex: 1,
        pageSize: 20
      },
      positionList: [],
      accessoryRelationStatusList: [], //单据状态
      accessoryClassList: [], //辅料类型
      total: 0,
      columnData: [
        {
          fieldName: "productCode",
          display: true,
          fieldLabel: "产品ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "productName",
          display: true,
          fieldLabel: "产品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "productNo",
          display: true,
          fieldLabel: "产品型号",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "status",
          display: true,
          fieldLabel: "单据状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "accessoryName",
          display: true,
          fieldLabel: "辅料名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "processName",
          display: true,
          fieldLabel: "安装位置",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "accessoryId",
          display: true,
          fieldLabel: "辅料ID",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "amount",
          display: true,
          fieldLabel: "用量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "operationUserName",
          display: true,
          fieldLabel: "操作人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "operationTimeStr",
          display: true,
          fieldLabel: "最后修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      dialogType: 1, //1新增2编辑3详情
      addVisible: false,
      detailInfo: {}, //点击详情的详情信息
      tableVisible: false,
      clickId: ""
    };
  },
  mixins: [listPage, base, listPageReszie],
  watch: {},
  computed: {},
  components: { AddProductRelationshipDialog, InstallPosition },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      let promiseList = [
        findDownList(["maintenanceType", "accessoryClass"]),
        getPosition()
      ];
      let [enumRes, positionRes] = await Promise.all(promiseList);
      //单据状态
      this.accessoryRelationStatusList = this.getFreezeResponse(enumRes, {
        path: "data.maintenanceType"
      });
      //辅料类型
      this.accessoryClassList = this.getFreezeResponse(enumRes, {
        path: "data.accessoryClass"
      });
      //安装位置下拉框
      this.positionList = this.getFreezeResponse(positionRes, {
        path: "data"
      });
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1,
          disabled: !row.productCode
          //  row.status == "未维护" ||
        },
        {
          label: "详情",
          id: 2,
          disabled: !row.productCode
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.handleAdd(2, row);
      }
      if (val.id == 2) {
        this.handleAdd(3, row);
      }
      this.clickId = row.productId;
    },
    //格式化tableData
    formatList(data) {
      data.map((item, index) => {
        let startIndex =
          (this.searchForm.pageIndex - 1) * this.searchForm.pageSize;
        if (item.accessoryList.length) {
          item.children = item.accessoryList.filter((item, idx) => idx != 0);
          item.accessoryName = item.accessoryList[0].accessoryName;
          item.accessoryId = item.accessoryList[0].accessoryId;
          item.amount = item.accessoryList[0].amount;
          item.operationTimeStr = item.accessoryList[0].operationTimeStr;
          item.operationUserName = item.accessoryList[0].operationUserName;
          item.productProcessId = item.accessoryList[0].productProcessId;
          item.processName = item.accessoryList[0].processName;
        } else {
          item.children = [];
        }
        item.sortNum = startIndex + index + 1;
      });
      return data;
    },
    isRender(row) {
      if (row.row.productId == undefined) {
        return "disabled-column";
      }
    },
    //查看安装位置
    lookPosition() {
      this.tableVisible = true;
    },
    //处理启用禁用
    changeStatus(status) {
      if (!this.selColumns.length) {
        this.$message.warning("未勾选数据");
        return;
      }
      if (this.selColumns.some(item => item.status == "未维护")) {
        this.$message.warning("未维护的数据不能启用和禁用");
        return;
      }
      let tipTitle =
        status == 1 ? "是否确认启用勾选的数据？" : "是否确认停用勾选的数据？";
      let title = status == 1 ? "启用" : "禁用";
      this.$confirm(tipTitle, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.loading = true;
          this.batchEnable(status);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //批量启用请求
    async batchEnable(isEnable) {
      try {
        let res = await batchEnableRelation({
          isEnable,
          productAccessoriesIds: this.selColumns.map(
            item => item.productAccessoriesId
          )
        });
        if (res.code == "OK") {
          if (isEnable == 1) {
            this.$message.success("启用成功");
          } else {
            this.$message.success("停用成功");
          }
          this.selColumns = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //无需维护
    handleMaintenance() {
      if (!this.selColumns.length) {
        this.$message.warning("未勾选数据");
        return;
      }
      this.$confirm("确认这些产品都不需要绑定辅料吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.loading = true;
          this.unRelationReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //无需维护请求
    async unRelationReq() {
      try {
        let res = await unRelationProductAccessory({
          productCodeList: this.selColumns.map(item => item.productCode)
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");
          this.queryList();
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    // 导入
    importXlxs() {
      let route = {
        name: "uploadCenter",
        params: {
          refresh: true,
          moduleKey: "product_accessory_import",
          origin: "productManagement/productAccessoriesStore",
          maxDataSize: 500,
          serverName: E3_BL_EWOS
        }
      };
      this.routerPush(route);
    },
    handleAdd(type) {
      this.dialogType = type;
      this.addVisible = true;
    },
    //关闭安装位置弹框
    close() {
      this.tableVisible = false;
    },
    //  导出
    exportData() {
      exportData(this, exportProductAccessory);
    }
  }
};
</script>
<style lang="scss" scoped>
/* 隐藏多选框 */
/deep/.disabled-column .el-checkbox__input {
  display: none !important;
}
</style>
